<template>
  <div class="heartRate">
    <!-- <div class="title">
			<img src="@/assets/bracelet_img/29.png" mode="">
			<span>心率</span>
		</div>
		<div class="blackBar">
			<div class="greenBar mar_10"></div>
			<span class="mar_10">一</span>
			<span class="mar_10">起</span>
			<span class="mar_10">社</span>
			<span class="mar_10">交</span>
			<span class="mar_10">赚</span>
			<span>钱</span>
		</div> -->
    <watch-head :title="$t('心率')"
                :img="require('@/assets/bracelet_img/29.png')"></watch-head>
    <div class="calorieContent">
      <div class="card">
        <div class="status" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('心率')}}(BPM)</div>
        <div class="main">
          <!-- <van-circle v-model="currentRate" rate="10" layer-color="#E4EAEA" color="#A7F264" size="200px">
						<div style="color: #000;">van-circle</div>
					</van-circle> -->
          <div class="circle">
            <CircleProgress :radius="10"
                            :progress="max_record"
                            :isAnimation="true"
                            barColor="#A7F264"
                            backgroundColor="#E4EAEA">
              <div class="text"
                   style="color: #000;">
                <img src="@/assets/bracelet_img/30.png"
                     alt="">
                <div class="number">{{ record }}</div>
                <div class="kcal">BPM</div>
              </div>
            </CircleProgress>
          </div>
          <div id="echartsHeart"
               class="echarts"></div>
        </div>
      </div>
    </div>
    <div class="btm">
      <!-- <div class="refresh">刷新</div> -->
      <div class="request_add">
        <div class="left">
          <div class="box">
            <img src="@/assets/bracelet_img/5.png"
                 alt="">
          </div>
        </div>
        <div class="right">
          <div class="request"
              :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
               @click="getEchartsData('day')">{{$t('每日')}}</div>
          <div class="add"
              :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
               @click="getEchartsData('month')">{{$t('每月')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleProgress from 'vue-circleprogressbar';
import * as echarts from 'echarts';
import watchHead from '@/components/watchHead'
import { getMotionStatistics, getPercentage } from '../../../api/api'
import { mapState } from 'vuex'
export default {
  components: {
    CircleProgress,
    watchHead
  },
  data () {
    return {
      timer: null,
      myChart: null,
      record: 0,
      max_record: 0,
      option: {
        grid: {
          left: 40,
          top: 20,
          right: 15,
          bottom: 40,
        },
        xAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          type: 'category',
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          // data: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          data: [],
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,//使x轴文字显示全
            fontSize: 10,
            fontFamily: 'AeroMaticsRegular',
          }
        },
        yAxis: {
          type: 'value',
          // min: 'dataMin',
          // max: 'dataMax',
          // minInterval: 30,
          splitNumber: 4,
          axisLabel: {
            //y轴文字的配置
            show: true,
            fontSize: 15,
            fontFamily: 'AeroMaticsRegular',
          },
        },
        series: [
          {
            name: 'heartRate',
            // data: [150, 230, 224, 218, 135, 147, 260, 150, 230, 224, 218, 135],
            data: [],
            type: 'line',
            lineStyle: {
              color: '#8FF9F6'
            },
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0.5,
                x2: 1,
                y2: 0.5,
                colorStops: [  // 渐变颜色
                  {
                    offset: 0,
                    color: '#FFFFFF',
                  },
                  {
                    offset: 1,
                    color: '#C7FCFA',
                  },
                ],
                global: false,
              }
            }
          }
        ],
      }
    }
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () { },
  mounted () {
    this.waitForGlobal();
  },
  methods: {
    getTodaySport (field) {
      getPercentage({ field }).then(res => {
        if (res.code == 0) {
          this.record = res.data[1]
          this.max_record = res.data[0] ? 100 * (res.data[1] / res.data[0]) : 0
        }
      })
    },
    getEchartsData (date) {
      this.myChart.showLoading();
      getMotionStatistics({
        date_type: date
      }).then(res => {
        if (res.code == 0) {
          let arrData = [], categories = [];
          res.data.data.forEach(element => {
            arrData.push(element.heart_rate);
            categories.push(element.date);
          });
          this.myChart.hideLoading();
          this.myChart.setOption({
            xAxis: {
              data: categories
            },
            series: [
              {
                // 根据名字对应到相应的系列
                name: 'heartRate',
                data: arrData
              }
            ]
          });
        }
      })
    },
    goTo () {
      this.$router.push({
        path: "/friendAdd"
      })
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.$nextTick(() => {
          this.myChart = echarts.init(document.getElementById('echartsHeart'));
          // 绘制图表
          this.myChart.setOption(this.option);
          this.getEchartsData('day');
          this.getTodaySport('heart_rate');
        })
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  }
}
</script>

<style lang="less" scoped>
// div{
// 	box-sizing: border-box;
// }
.heartRate {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/26.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  //   .title {
  //     padding: 0 8px;
  //     background-color: #6480f3;
  //     display: flex;
  //     align-items: center;
  //     font-family: '071-SSRuiFengTi';
  //     font-size: 45px;
  //     margin-bottom: 4px;
  //     img {
  //       width: 35px;
  //       height: 35px;
  //       margin-right: 8px;
  //     }
  //   }
  //   .blackBar {
  //     // padding: 2rpx;
  //     background-color: #2b2b2b;
  //     display: flex;
  //     align-items: center;
  //     font-family: 'TsangerYuYangT-W03';
  //     font-size: 12px;
  //     .greenBar {
  //       width: 23px;
  //       height: 10px;
  //       background-color: #a7f264;
  //     }
  //     .mar_10 {
  //       margin-right: 5px;
  //     }
  //   }
  .calorieContent {
    padding: 25px 20px 80px;
    .card {
      width: 100%;
      // height: 500px;
      background-color: #ffffff;
      border-radius: 15px;
      padding: 18px 0 0px;
      .status {
        width: 55%;
        background-image: linear-gradient(115deg, #8ff9f6 0%, #ffffff 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 23px;
        color: #2b2b2b;
        padding: 1px 12px 5px;
        margin-bottom: 40px;
      }
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle {
          width: 230px;
          height: 230px;
          position: relative;
          .content {
            width: 230px;
            height: 230px;
          }
          /deep/.center_text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .text {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            img {
              width: 70px;
              height: 63px;
              margin-bottom: 12px;
            }
            .number {
              font-family: 'KenyanCoffeeRg-Bold';
              font-size: 39px;
              color: #2b2b2b;
            }
            .kcal {
              font-family: 'AeroMaticsBold';
              font-size: 18px;
              color: #6480f3;
            }
          }
        }
        .echarts {
          width: 100%;
          height: 230px;
        }
      }
    }
  }
  .btm {
    width: 100%;
    font-family: '071-SSRuiFengTi';
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 8px;
    .request_add {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 17%;
        height: 52px;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 0 0 36px 0;
        padding: 8px 8px 8px 0;
        .box {
          background-color: #2b2b2b;
          border-radius: 0 0 32px 0;
          border: solid 3px #7736fe;
          opacity: 0.9;
          display: flex;
          align-items: center;
          justify-items: center;
          padding-left: 8px;
          height: 100%;
          img {
            width: 28px;
            height: 20px;
          }
        }
      }
      .right {
        width: 82%;
        height: 52px;
        display: flex;
        align-items: center;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 36px 0 0 0;
        padding: 0 60px 0 8px;
        text-align: center;

        .request {
          background-color: #8ff9f6;
          border-radius: 32px 4px 4px 4px;
          border: solid 3px #261e36;
          margin-right: 8px;
          width: 120px;
          font-size: 24px;
          color: #2b2b2b;
        }
        .add {
          background-color: #5c65cb;
          border-radius: 4px 4px 16px 4px;
          border: solid 3px #261e36;
          width: 120px;
          font-size: 24px;
          color: #2b2b2b;
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .heartRate {
    .btm {
      width: 430px;
    }
  }
}
</style>
